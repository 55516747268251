#layout-grid {
    height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr;
}

#content-grid {
    display: grid;
    grid-template-columns: 300px 1fr;
    /* grid-template-columns: auto 1fr; */
    overflow-y: hidden;
}

@media screen and (max-width: 800px) {
    #content-grid {
        grid-template-columns: auto 1fr;
    }
}

#content-sidebar {
    padding: 1em;
    overflow-y: auto;
    border-right: 1px solid #f8f9fa;
}

#content-sidebar-offcanvas {
    padding: 0.5em;
}

#content-sidebar-offcanvas:hover {
    background-color: #f8f9fa;
}

#content-data {
    /* display: grid; */
    /* grid-template-rows: auto 1fr; */
    overflow-y: auto;
}

#content-pagination {
    z-index: 1;
    background-color: #f8f9fa;
    position: sticky;
    top: 0;
    padding: 1em 0;
}

#content-title {
}

.clickable {
    cursor: pointer;
}

#content-additional-options {
    z-index: 1;
    background-color: #f8f9fa;
    position: sticky;
    top: 0;
    padding: 1em 0;
}